<template>
  <div>
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <img src="~/assets/img/icon_white.png" loading="lazy" class="mx-auto h-14 w-auto" alt="MattePaint" />
      <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-white">
        Reset your password
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
      <UForm ref="form" class="grid grid-cols-2 gap-6" :state="form" :schema="schema" @submit="onSubmit">
        <UFormGroup
          v-slot="{ error }"
          label="New Password"
          name="password"
          class="col-span-2"
          :error="error.password"
          required
        >
          <UInput
            ref="password"
            v-model="form.password"
            type="password"
            autocomplete="new-password"
            autocapitalize="off"
            spellcheck="false"
            tabindex="4"
            icon="i-heroicons-lock-closed"
            :trailing-icon="error && 'i-heroicons-exclamation-triangle-20-solid'"
            :loading="busy"
            required
          />
        </UFormGroup>

        <UFormGroup
          v-slot="{ error }"
          label="Confirm Password"
          name="password_confirm"
          class="col-span-2"
          :error="error.password_confirm"
          required
        >
          <UInput
            ref="password_confirm"
            v-model="form.password_confirm"
            type="password"
            autocomplete="new-password"
            autocapitalize="off"
            spellcheck="false"
            tabindex="4"
            icon="i-heroicons-lock-closed"
            :trailing-icon="error && 'i-heroicons-exclamation-triangle-20-solid'"
            required
          />
        </UFormGroup>

        <div class="col-span-2">
          <button type="submit" tabindex="5" class="relative flex w-full justify-center rounded-md bg-orange-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500">
            <span :class="[busy && 'text-transparent']">Update your password</span>
            <div class="absolute w-full h-full flex justify-center items-center top-0">
              <GLoader v-if="busy" class="m-auto h-6" />
            </div>
          </button>
        </div>
      </UForm>

      <p v-if="false" class="mt-10 text-center text-sm text-gray-100">
        <NuxtLink :to="loginLink" class="font-semibold leading-6 text-orange-400 hover:text-orange-300">
          Log in to your account
        </NuxtLink>
      </p>
    </div>
  </div>
</template>

<script>
import { z } from 'zod';

export default {
  props: {
    modal: {
      type: Boolean,
      default: () => true,
    },
  },
  emits: ['close'],
  data() {
    return {
      busy: false,
      session: null,
      form: {
        password: null,
        password_confirm: null,
      },
      error: {
        password: null,
        password_confirm: null,
      },
      schema: z.object({
        password: z.string({ required_error: 'Password is required', invalid_type_error: 'Password is required' }).min(8, 'Must be at least 8 characters'),
        password_confirm: z.string({ required_error: 'Confirm Password is required', invalid_type_error: 'Confirm Password is required' }).min(8, 'Must be at least 8 characters')
      })
    }
  },
  computed: {
    loginLink() {
      const { $route } = this;
      const { fullPath, query } = $route;

      const { redir = null } = query || {};
      if (redir) {
        return `/login/?redir=${encodeURIComponent(redir)}`;
      }

      if (!this.modal) {
        return '/login/';
      }

      return `/login/?redir=${encodeURIComponent(fullPath)}`;
    },
  },
  watch: {
    'form.password'() {
      this.error.password = null;
    },
    'form.password_confirm'() {
      this.error.password_confirm = null;
    },
  },
  mounted() {
    try {
      this.$refs.password.input.focus();
    } catch (e) {}
  },
  methods: {
    async onSubmit() {
      if (this.busy) {
        return;
      }
      this.error.password = null;

      if (this.form.password !== this.form.password_confirm) {
        this.Toast('error', 'Passwords do not match');
        this.error.password = 'Passwords do not match';
        try {
          this.$refs.password.input.focus();
        } catch (e) {}
        return
      }
      this.busy = true;
      const res = await this.$store.auth.GetAuthResetPassword(this.form.password);
      this.busy = false;
      if (res.error) {
        const msg = res.error?.message || res.error?.error || res.error;
        if (msg === 'invalid') {
          this.Toast('error', 'Unable to process the request');
          this.error.password = 'Unable to process the request';
        } else if (msg === 'banned') {
          this.Toast('error', 'Your account has been banned');
          this.error.password = 'Your account has been banned';
        } else if (msg === 'weak password') {
          this.Toast('error', 'This password is not allowed');
          this.error.password = 'This password is not allowed';
          // this.form.password = null;
          this.form.password_confirm = null;
          try {
            this.$refs.password.input.focus();
          } catch (e) {}
        } else if (msg === 'ip_denied') {
          this.Toast('error', 'You cannot get access from this IP Address');
        } else {
          this.Toast('error', 'There was a problem. Please try again later');
        }
        return;
      }
      this.Toast('success', 'Your password has been updated');
      const { user } = res;
      this.$store.user.SetUser(user);
      this.$emit('close');
    },
  }
}
</script>